<template>
  <v-dialog
    v-model="dialog"
    :width="$vuetify.breakpoint.smAndDown ? '100%' : '30%'"
  >
    <template v-slot:activator="{ on: onDialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            elevation="0"
            @click="open"
            v-on="{ ...onDialog, ...onTooltip }"
            :block="block"
            icon
            small
            :disabled="disabled"
          >
            <v-icon small>mdi-plus-circle</v-icon>
          </v-btn>
        </template>
        {{ $t("add", { name: "" }) }}
      </v-tooltip>
    </template>
    <v-card id="desing">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
        >{{ $t("add", { name: $tc("design") }) }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="design-form"
        @submit.prevent="validateForm('design-form')"
      >
        <v-card-text>
          <v-row justify="center">
            <v-col class="py-0" cols="8" v-if="false">
              <label v-text="$t('designs.name')"></label>
              <v-text-field
                outlined
                hide-details
                dense
                v-model="form.name"
                data-vv-validate-on="blur"
                v-validate="''"
                :error-messages="errors.collect('design-form.name')"
                color="secondary"
                data-vv-name="name"
              ></v-text-field>
            </v-col>

            <v-col class="py-0" cols="8">
              <label v-text="$t('designs.price')"></label>
              <number-input-with-separators
                :value="form"
                valueKey="price"
                ref="money1"
                rules="required"
                outlined
                hideDetails
                dense
                color="secondary"
                @change="select()"
                v-bind:class="{ 'error--text': form.price > form.max_price }"
              ></number-input-with-separators>
            </v-col>
            <v-col class="py-0" cols="8">
              <label v-text="$t('designs.max_price')"></label>
              <number-input-with-separators
                :value="form"
                valueKey="max_price"
                ref="money3"
                rules="required"
                outlined
                hideDetails
                dense
                color="secondary"
                v-bind:class="{ 'error--text': form.price > form.max_price }"
              ></number-input-with-separators>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1"></div>

          <v-btn
            outlined
            @click="
              dialog = false;
              files = [];
            "
            style="height: 25px"
            elevation="0"
            class="mr-1"
            >{{ $t("cancel") }}</v-btn
          >

          <v-btn
            style="color: #363533; height: 25px; padding-top: 10px !important"
            elevation="0"
            type="submit"
            class="mr-1 ml-0"
            @click="action = 'save'"
            ><v-icon style="margin-right: 5px" size="14px">$save</v-icon
            >{{ $t("save", { name: "" }) }}</v-btn
          >
          <v-btn
            v-if="appointment.extra_customers.length <= 1"
            style="color: #363533; height: 25px; padding-top: 10px !important"
            elevation="0"
            type="submit"
            :disabled="!appointment.customer.user.email"
            class="ml-0"
            @click="action = 'send'"
            ><v-icon style="margin-right: 5px" size="14px">$send</v-icon>
            {{ $t("send", { name: "" }) }}
          </v-btn>
          <v-dialog v-else v-model="dialog_customer" width="570">
            <template v-slot:activator="{}">
              <v-btn
                style="
                  color: #363533;
                  height: 25px;
                  padding-top: 10px !important;
                "
                type="submit"
                elevation="0"
                class="ml-0"
                @click="action = 'send'"
              >
                <v-icon style="margin-right: 5px" size="14px">$send</v-icon>
                {{ $t("send", { name: "" }) }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>{{ $t("customers.choose") }}</v-card-title>
              <div class="close">
                <v-btn icon @click="dialog_customer = false" small>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <v-card-text>
                <v-row>
                  <v-col v-for="c in appointment.extra_customers" :key="c.id">
                    <v-card
                      style="border: 1px solid var(--v-primary-base)"
                      @click="
                        customer_id = c.id;
                        validateForm('design-form');
                      "
                    >
                      <v-card-title
                        style="
                          font-size: 18px !important;
                          text-decoration: none !important;
                        "
                        class="text-decoration-none"
                      >
                        {{ c.user.first_name || "-" }} {{ c.user.last_name }}
                      </v-card-title>
                      <v-card-text>
                        <p class="text-center">{{ c.user.email || "-" }}</p>
                        <p class="text-center">{{ c.user.phone || "-" }}</p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUpload from "vue-upload-component";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      dialog_customer: false,
      files: [],
      form: {},
      token_payments: [],
      customer_id: null,
      action: "save",
    };
  },
  props: ["appointment", "sessions", "block", "disabled"],
  computed: {
    payment_appointment() {
      let s = this.appointment.studio.user.setting.find(
        (x) => x.key === "payment_appointment"
      );
      if (s) return s.value === "1";
      return false;
    },
  },
  components: {
    FileUpload,
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.fetch();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("appointments", ["addAppointmensDesign"]),
    ...mapActions("tattooers", ["getTokenPayments"]),
    fetch() {
      this.getTokenPayments(this.appointment.tattooer.id).then((data) => {
        this.token_payments = data;
      });
    },
    select() {
      if (this.form.size.type === "percentage") {
        let porcentaje = (this.form.price * this.form.size.price) / 100;
        porcentaje = Math.round(porcentaje * 100) / 100;
        this.form.token_payment = porcentaje;
      } else {
        this.form.token_payment = this.form.size.price;
      }
      this.$refs.money2.change();
    },
    async validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      let result = await this.$validator.validateAll(scope);
      let money_val_1 = true;
      let money_val_2 = true;
      let money_val_3 = true;
      if (this.$refs.money1) money_val_1 = await this.$refs.money1.validate();
      if (this.$refs.money2) money_val_2 = await this.$refs.money2.validate();
      if (this.$refs.money3) money_val_3 = await this.$refs.money3.validate();

      if (result && money_val_1 && money_val_2 && money_val_3) {
        //console.log("VALIDADO");
        let formData = new FormData();
        for (let key in this.form) {
          switch (key) {
            case "size":
              formData.append(key, this.form[key].size);
              break;
            default:
              formData.append(key, this.form[key]);
          }
        }
        formData.append("appointment_id", this.appointment.id);
        this.files.forEach((file) => {
          formData.append("files[]", file.file);
        });

        formData.append("send", this.send);
        formData.append("customer_id", this.customer_id);

        console.log(
          this.appointment.extra_customers.length <= 1,
          this.customer_id
        );
        if (this.appointment.extra_customers.length <= 1 || this.customer_id)
          this.addAppointmensDesign(formData).then((data) => {
            this.dialog = false;
            this.$store.dispatch("appointments/getPendingAppointments");
            this.$emit("update", data);
            if ((this.action = "save")) this.$alert(this.$t("save_ok"));
            else if (this.appointment.customer.user.email) {
              this.$alert(this.$t("send_ok"));
            } else {
              this.$alert(this.$t("save_ok_no_mail"));
            }
          });
        else this.dialog_customer = true;
      }
    },
    open() {
      this.form = {};
      this.$refs.money1.temp = null;
      this.$refs.money2.temp = null;
      this.$refs.money3.temp = null;
    },
  },
};
</script>
<style lang="sass">
#desing
  .file-upload
    label
      height: 70px
  label
    height: 25px
    display: block

.upload
  *
    cursor: pointer
  label
    height: 100% !important
</style>
